.game-container {
    display: flex;
    /* background-image: url('/path-to-your-board-image.jpg'); */
    background-size: cover;
    height: 100vh;
    padding: 20px;
  }
  
  .left-panel {
    width: 20%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-right: 2px solid #ccc;
  }
  
  .right-panel {
    width: 80%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .question-item.active {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .options {
    margin: 20px 0;
  }
  
  .option {
    margin-bottom: 10px;
  }
  
  .submit-btn {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .score {
    font-size: 18px;
    font-weight: bold;
  }
  
  .more-options-btn {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .more-options {
    margin-top: 10px;
  }
  
  .more-options .option {
    margin-bottom: 5px;
  }
  /* Style for word drop areas */
.word-drop-area {
  border: 2px dashed #ccc;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  min-height: 50px;
}

.word-drop-area.correct {
  border-color: green;
}

.word-drop-area.incorrect {
  border-color: red;
}

/* Word styling */
.words {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.draggable-word {
  background-color: #4c99af;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.draggable-word.correct {
  background-color: rgb(110, 214, 50);
}

/* Hover effect for words */
.draggable-word:hover {
  transform: scale(1.1);
  background-color: #454ba0;
}

/* Button container styling */
.button-container {
  margin-top: 20px;
}
