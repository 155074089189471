body {
  margin: 0;
  font-family: "Helvetica", "Times New Roman", Arial, sans-serif;
  /* font-weight: 700; */
  /* color: black !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255, 254, 254);
}

code {
  font-family: "Helvetica", "Times New Roman", Arial, sans-serif;
}

/* main{
  height: 50vh;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* navigation css starts here */

.nav_container {
  margin: 0;
  padding: 0;
  width: 100%;
  
  max-width: 2880px;
  box-sizing: border-box;
  background: #decfb2;
}

.navbar {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  width: 100%;
}

.logo {
  flex-basis: auto;
  align-items: center;
}

.logo img {
  max-width: 100px;
  height: 100px;
  object-fit: contain;
}

.logo-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-link {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.nav-link:hover {
  color: #385537;
}

.nav-link li {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 0;
  justify-self: center;
  align-self: center;
  position: relative;
}

.nav-link > li > a {
  padding: 10px;
  color: #333 !important;
}

.nav-link > li > a:hover {
  color: #385537;
}

.nav-link a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-link a:hover {
  color: #333;
}

.services-dropdown .dropdown {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  list-style: none;
  margin: 0;
  width: 250px;
  z-index: 1000;
  left: 0;
  border-radius: 4px;
}

.services-dropdown .dropdown li:hover {
  background-color: #decfb2;
  color: #385537;
  border-radius: 4px;
}

.services-dropdown:hover .dropdown,
.services-dropdown .dropdown.open {
  display: block;
}

.services-dropdown .nested-dropdown {
  display: none;
  position: absolute;
  right: 100%;
  top: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  width: 250px;
  z-index: 1000;
  list-style: none;
  border-radius: 4px;
}

.services-dropdown .nested-dropdown li:hover {
  background-color: #decfb2;
  color: #385537;
  border-radius: 4px;
}

.services-dropdown .dropdown li:hover .nested-dropdown {
  display: block;
}

.services-dropdown .dropdown li:hover .nested-dropdown li:hover {
  background: #decfb2;
  border-radius: 4px;
  justify-content: center;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar {
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }

  .logo {
    flex-basis: auto;
    text-align: left;
    padding-left: 40px;
  }

  .logo-menu {
    justify-content: space-evenly;
    width: 100%;
  }

  .nav-link {
    flex-direction: column;
    flex-basis: 100%;
    display: none;
  }

  .nav-link.active {
    display: flex;
  }

  .nav-link li {
    margin-left: 0;
    padding: 10px 0;
    text-align: center;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    margin-left: auto;
  }

  .hamburger div {
    width: 30px;
    height: 4px;
    background-color: #333;
    margin: 5px 0;
  }

  .services-dropdown .dropdown {
    display: none;
    position: relative;
    background-color: #fff;
    padding: 10px 0;
    list-style: none;
    margin: 0;
    width: 100%;
  }

  .services-dropdown .dropdown.open {
    display: block;
  }

  .services-dropdown .nested-dropdown {
    display: none;
  }

  .services-dropdown .nested-dropdown.open {
    display: block;
  }

  .services-dropdown ul {
    position: relative;
    left: 0;
  }

  .services-dropdown ul li {
    text-align: left;
    padding: 10px;
    cursor: pointer;
  }
}

/* ends here */

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 5px;
  background-color: #decfb2;
  color: #385537;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.whatsapp-icon {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin: 0;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 5px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer {
  background-color: #385537;
}

.ftr-sb_footer {
  display: flex;
  flex-direction: column;
}

.footer-link {
  display: flex;
  align-items: center;
  color: #decfb2;
  text-decoration: none;
  margin-right: 15px;
}

.footer-link svg {
  margin-right: 5px;
}

.ftr-section_padding {
  padding: 2rem 4rem;
}

/* .ftr-contact-info {
  margin-top: 10px;
  color: #decfb2;
} */
ftr-contact-info svg {
  height: 35px;
  width: 35px;
}

.ftr-sb_footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.ftr-sb_footer-links-business,
.ftr-sb_footer-links-resource,
.ftr-sb_footer-links-social,
.ftr-sb_footer-query {
  width: 200px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  color: white;
}

.ftr-sb_footer-query > h4 {
  color: #decfb2;
}

.query-form > button {
  background-color: #decfb2;
  color: #385537;
}

.ftr-sb_footer-links-resource > h4 {
  color: #decfb2;
}

.ftr-sb_footer-links-resource > a {
  color: #fff;
}

.ftr-sb_footer-links-social > h4 {
  color: #decfb2;
}

.ftr-sb_footer-links-social > p {
  color: #fff;
}

.socialmedia {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  z-index: 10;
}

.socialmedia p {
  font-size: 8px;
}

.socialmedia svg {
  width: 40px;
  height: 40px;
  color: #decfb2;
  /* background-color: #decfb2; */
  /* border-radius: 50%; */
  font-size: 20px;
  /* padding: 8px; */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.socialmedia svg:hover {
  transform: scale(1.1);
}

a {
  color: rgb(175, 175, 179);
  text-decoration: none;
}

h4 {
  color: #f6670ef3;
}

.ftr-sb_footer-query .query-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.query-input,
.query-textarea {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  border: none;
}

.query-input {
  width: 100%;
}

.query-textarea {
  width: 100%;
  resize: none;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #f6670ef3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-btn:hover {
  background-color: #e55608;
}

.success-message {
  color: #28a745;
  margin-top: 10px;
}

hr {
  border-color: white;
}

.ftr-sb_footer-below {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  color: white;
}

.ftr-sb_footer-below-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ftr-sb_footer-below-links p {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #fff;
}

.ftr-sb_footer-copyright p {
  color: #fff;
}

.ftr-sb_footer-copyright > span {
  color: #decfb2;
}

@media screen and (max-width: 850px) {
  .ftr-section_padding {
    padding: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .ftr-section_padding {
    padding: 1rem;
  }

  .ftr-sb_footer-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .ftr-sb_footer-below {
    flex-direction: column;
  }

  .socialmedia svg {
    width: 35px;
    height: 35px;
  }

  .ftr-sb_footer-query {
    width: 100%;
  }
}

@media screen and (min-width: 321px) {
  .ftr-sb_footer-below-links {
    flex-direction: row;
    justify-content: flex-start;
  }

  .ftr-sb_footer-below-links p {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 551px) and (max-width: 850px) {
  .ftr-sb_footer-below {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ftr-sb_footer-below-links {
    flex-direction: row;
    justify-content: flex-end;
  }

  .ftr-sb_footer-below-links p {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .ftr-sb_footer-below {
    flex-direction: column;
  }

  .ftr-sb_footer-below-links {
    flex-direction: column;
    align-items: center;
  }

  .ftr-sb_footer-below-links p {
    margin-left: 0;
    margin-top: 1rem;
  }

  .ftr-sb_footer-query {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  color: #e05d0c; /* Example color */
}

.paragraph {
  font-size: 16px;
  text-align: center;
}

.overlay-image {
  width: 50%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 600px) {
  .heading {
    font-size: 20px; /* Adjust heading size */
  }

  .paragraph {
    font-size: 14px; /* Adjust paragraph size */
  }

  .text-container {
    flex-direction: column; /* Stack on small screens */
    align-items: center; /* Center text */
  }
}
.responsive-image {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure the image scales with its container */
  max-height: 500px; /* Adjust as needed */
}
socialmedia h4 {
  padding-bottom: 20px;
}
